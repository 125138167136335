
import firebase from "../firebaseApp";
import { mapState, mapActions } from "vuex";
import Design from "@/common/helpers/Design";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCustomToken,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
} from "firebase/auth";

export default {
  name: "LoginEmail",
  data() {
    return {
      form: {
        email: this.$store.state.emailAddress,
        password: "",
      },
      microsoftSVG: "",
      error: null,
      database: "dev",
      showSelectDB: false,
      designIcons: [],
      designImages: {},
      designColorSchema: {},
      showPasswordField: true,
      showLoginButton: true,
      showLinkAccountsButton: false,
      showMicrosofAuthenticationNotConfigured: false,
      disableEmailField: false,
      pendingCredentials: null,
      auth: getAuth(firebase.app()),
    };
  },
  methods: {
    ...mapActions("portal", ["initPortalMode"]),
    async onResetPassword() {
      this.$router.push({ name: "reset" });
    },
    onLogin() {
      this.$router.push({ name: "login" });
    },
    setStateEmailAddress() {
      this.$store.state.emailAddress = this.form.email;
    },
    submit() {
      const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (!emailReg.test(this.form.email) ? true : false) {
        this.$q.notify({
          // checks if the email is valid else you get a negative error
          color: "negative",
          message: "Vul een geldig email in.",
        });
      } else {
        signInWithEmailAndPassword(
          this.auth,
          this.form.email,
          this.form.password
        )
          .then(() => {
            //this.$router.push('/')
          })
          .catch((err) => {
            this.error = err.message;
            alert(`Login error: ${err.message}`);
          });
      }
    },
    loginWithMicrosoft() {
      const provider = new OAuthProvider("microsoft.com");
      signInWithPopup(this.auth, provider)
        .then(() => {})
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            this.pendingCredentials = OAuthProvider.credentialFromError(error);
            this.form.email = error.customData.email;
            fetchSignInMethodsForEmail(this.auth, error.customData.email).then(
              (methods) => {
                for (const method of methods) {
                  if (method === "password") {
                    this.disableEmailField = true;
                    this.showLoginButton = false;
                    this.showLinkAccountsButton = true;
                    return;
                  }
                }
              }
            );
          } else if (error.code === "auth/admin-restricted-operation") {
            this.showMicrosofAuthenticationNotConfigured = true;
          }
        });
    },
    onLinkAccount() {
      signInWithEmailAndPassword(this.auth, this.form.email, this.form.password)
        .then((result) => {
          return linkWithCredential(result.user, this.pendingCredentials).then(
            () => {
              console.log("succesfully linked accounts");
            }
          );
        })
        .catch((err) => {
          this.error = err.message;
          if (err.code === "auth/wrong-password") {
            alert(`Login error: Wrong password`);
          } else {
            alert(`Login error z: ${err.message}`);
          }
        });
    },
    setColorSchema() {
      Design.setSpecificColors(this.designColorSchema);
    },
    GoToSignUp() {
      this.$router.push({ path: "/sign-up" });
    },
    reloadPage() {
      this.$router.go();
    },
  },
  computed: {
    ...mapState(["PORTAL", "startPageAfterLogin", "portalDesignLoaded"]),
    onDatabase: {
      get() {
        return this.database;
      },
      set(value) {
        this.database = value;
        localStorage.setItem("databaseProd", value);
      },
    },
    design() {
      return this.PORTAL ? this.PORTAL.design : null;
    },
    iconsLoaded() {
      return Object.values(this.designIcons).length;
    },
    isDesignLoaded() {
      return this.portalDesignLoaded;
    },
    imagesBackgroundLoginUrl() {
      return this.iconsLoaded &&
        this.designImages &&
        this.designImages.backgroundLogin
        ? this.designImages.backgroundLogin.downloadableURL
        : "";
    },
  },
  watch: {
    startPageAfterLogin() {
      if (this.startPageAfterLogin) {
        this.$router.push(this.startPageAfterLogin);
      }
    },
    PORTAL() {
      if (this.PORTAL && this.PORTAL.design) {
        this.designColorSchema = this.PORTAL.design.colorSchema;
        this.designIcons = this.PORTAL.design.icons;
        this.designImages = this.PORTAL.design.images;
        document.title = this.PORTAL.title;
      }
      this.setColorSchema();
    },
  },
  async beforeMount() {
    this.database = localStorage.getItem("databaseProd") || "prod";
    await this.initPortalMode();
  },
  async mounted() {
    const token = this.$route.query.token;
    if(token){
      await signInWithCustomToken(this.auth, token).catch((err) => {
        alert(`Login error: ${err}`)
      })
    }
  }
};
