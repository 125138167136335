
  import {mapActions} from "vuex";
  import LoginEmail from "@/components/LoginEmail";

  export default {
    name: "ResetPassword",
    mixins: [LoginEmail],
    data() {
      return {
      };
    },
    methods: {
      ...mapActions("api", ["getPasswordResetLink"]),
      async submitReset() {
        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        if (!emailReg.test(this.form.email) ? true : false) {
          this.$q.notify({
            // checks if the email is valid else you get a negative error
            color: "negative",
            message: "Vul een geldig email in.",
          });
        } else {
          const passwordResetted = await this.getPasswordResetLink(this.form.email);
          if(passwordResetted){
            this.$q.notify({ message: `Aanvraag wachtwoord resetten succesvol afgerond.`, color: 'positive'}),
            this.$router.push('/login')
          }else{
            this.$q.notify({ message: `Fout tijdens het resetten van het wachtwoord.`, color: 'negative'})
          }
        }
      },
    },
    computed: {
      isReset() {
        return true;
      }
    },
  };
